.multi-column-html {
    position: relative;
    display: flex;
    align-items: center;

    &.bg-image {
        background-size: cover;
        background-position: center;
    }

    .overlay-image {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;

        .color-overlay {
            position: absolute;
            z-index: 1;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }
    }

    .navbar {
        padding: 0;
    }

    .bg-video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }

    .btn-playpause {
        appearance: none;
        -webkit-appearance: none;
        color: @color-white;
        background: transparent;
        border: none;
        font-size: 30px;
        padding: 20px;
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 2;

        &.playing .icon-play:before {
            content: '';
            width: 5px;
            height: 20px;
            display: inline-block;
            background: @color-white;
        }

        &.playing .icon-play:after {
            content: '';
            width: 5px;
            height: 20px;
            display: inline-block;
            background: white;
            margin-left: 5px;
        }
    }
}

.multi-column-intro {
    text-align: center;
    width: 100%;
    max-width: @text-block-width;
    margin: 0 auto;
    margin-bottom: @spacing-48;
    z-index: 99;
    position: relative;

    @media(min-width: @screen-sm) {
        margin-bottom: @spacing-80;
    }
}

.multi-column-icon {
    width: 65px;
    margin: 0 auto;
    margin-bottom: @spacing-24;

    svg {
        margin: -15px 0;
    }

    &.icon-locations svg {
        margin: -10px;
    }
}

.multi-column-container {
    width: 100%;
    position: relative;
    z-index: 1;

    &.text-color-light {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p,
        a:not(.btn-reversed) {
            color: @color-white;
        }
    }

    .html-column + .html-column {
        margin-top: @spacing-64;
    }

    & + .multi-column-container {
        margin-top: @spacing-64;
    }

    @media(min-width: @screen-sm) {
        display: flex;
        flex-wrap: wrap;
        width: calc(~"100% + @{spacing-32} * 2");
        margin: -@spacing-32;

        &.layout-full {
            display: block;
            width: 100%;
        }

        .html-column {
            padding: @spacing-32;

            & + .html-column {
                margin-top: 0;
            }
        }

        &.layout-half-half .html-column {
            width: 50%;
        }

        &.layout-onethird-twothirds .html-column {
            &:first-child {
                width: 33.33%;
            }

            &:last-child {
                width: 66.66%;
            }
        }

        &.layout-twothirds-onethird .html-column {
            &:first-child {
                width: 66.66%;
            }

            &:last-child {
                width: 33.33%;
            }
        }

        &.layout-onethird-onethird-onethird .html-column {
            width: 33.33%;
        }
    }
}

.flexible-column .multi-column-container {
    @media (min-width: @screen-sm) {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        gap: @spacing-32 * 2;
        width: 100%;
        margin: 0;

        .html-column {
            padding: 0;

            .btn.digital-community-btn {
                margin: 5px;
            }
        }
    }
}