﻿@import '../framework/imports.less';

// WX-4428 Remove this if not accepted
.larger-results-product-thumbs {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 24px;

    @media (max-width: @screen-xs) {
        gap: 12px;
    }

    .larger-image-product-thumb {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 16px;
        padding: 0;
        min-width: 155px;
        max-width: 250px;
        flex: 1 1 calc(50% - 24px); // 50% width minus gap

        .product-thumb-details {
            border-bottom: none;

            .product-thumb-image-wrapper {
                .product-thumb-image {
                    padding: 0;
                }
            }
        }

        .compare-section {
            border-top: @border-light;
            padding-top: 10px;
        }
    }
}

// WX-4428 Remove this if accepted
.product-thumb {
    display: block;
    white-space: normal;
    text-align: center;
    text-transform: none;
    padding: @grid-gutter-width / 2;
    font-size: @font-size-base;
    .make-xs-column(6);

    @media (min-width: @screen-xs-min) {
        width: percentage((4 / @grid-columns));
    }

    .make-lg-column(3);

    @media (min-width: @screen-sm-min) {
        padding: 15px;
    }

    @media (min-width: @screen-xl-min) {
        width: 20%;
    }
}

.product-thumb-details {
    border-bottom: @border-light;
    padding-bottom: 5px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
}

.product-thumb-link {
    color: @color-black;

    &:hover,
    &:active,
    &:focus {
        color: @color-black;
        text-decoration: none;
    }
}

.product-thumb-image-wrapper {
    height: 0;
    display: block;
    position: static;
    margin-bottom: 10px;
    padding-bottom: 100%;
}

.product-thumb-image {
    padding: 0 5px 10px 5px;
    width: 100%; //TODO temporary override until we specify new image size
}

.product-thumb-overlay {
    position: absolute;
    bottom: @margin-small;
    left: 10px; // to offset padding
    z-index: @z-index-2;
}

.product-thumb-promotion-overlay {
    position: absolute;
    left: 0;
    bottom: auto;
    max-width: 90%;
    max-height: 90%;
}

.product-thumb-title {
    display: block;
}

.product-thumb-price {
    display: block;
    font-weight: @font-weight-bold;
    margin-bottom: 5px;

    &.slash {
        font-weight: normal;
        margin-bottom: 0;
    }
}

.product-thumb-sale {
    display: inline-block;
    font-size: @font-size-small;
    line-height: 1em;
    text-transform: uppercase;
    padding: 3px 3px 1px 3px;
    border-radius: 2px;
    color: #fff;
    background-color: @color-red;
    margin-right: 0.3em;
    vertical-align: baseline;
}

.product-thumb-swatches {
    text-align: center;
    font-size: 0;
}

.product-thumb-swatch {
    width: 16.6666%;
    display: inline-block;
    padding: 1px;
    cursor: pointer;
    vertical-align: middle;
}

.product-thumb-view-more {
    font-size: 11px;
    color: @color-gray-dark;
    display: inline-block;
    width: 16.6666%;
    vertical-align: middle;
    text-align: center;
    line-height: 1em;
    height: 100%;

    &:hover {
        color: @color-gray-dark;
        text-decoration: none;
    }
}

.compare-label {
    white-space: nowrap;

    .compare-validation-message {
        display: none;
        padding: 0 1%;

        em {
            color: @color-red;
            white-space: normal;
            display: block;
        }
    }
}

.compare-cbx {
    & + a {
        display: none;
        cursor: pointer;
    }

    &:checked {
        & + a {
            display: inline;
        }

        & ~ span {
            display: none;
        }
    }
}

.related-products .wishlist-text {
    @media (max-width: @screen-sm) {
        display: none;
    }
}

.product-thumb-reviews {
    font-size: 12px;
    white-space: nowrap;
}

.product-thumb-review-stars {
    color: @color-orange;
}

.product-thumb-review-count {
    color: @color-black;
}

.compatible-related-products {
    margin-top: 25px;
    border-top: 2px solid @color-gray-light;

    h3 {
        margin-top: 25px;
        margin-bottom: 10px;
        font-size: 24px;
    }

    .compatible-related-products-container {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        gap: 16px;
        align-self: flex-end;
        overflow: auto;
        white-space: nowrap;

        .product-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 8px;
            flex: 1 0 0;
            align-self: stretch;

            .img-frame {
                width: 120px;
                display: flex;
                padding: 8px;
                align-items: flex-start;
                gap: 8px;
                border-radius: 15px;

                .product-img {
                    width: 120px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    border-radius: 10px;
                }
            }

            .product-name {
                text-align: center;
                text-wrap: wrap;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 18px;
            }

            .product-color-and-size {
                text-align: center;
                text-wrap: wrap;
                font-size: 14px;
                font-style: normal;
                font-weight: 800;
                line-height: 18px;
            }
            // review stars
            .p-w-r .pr-snippet .pr-snippet-stars-png .pr-star-v4 {
                height: 12px;
                width: 12px;
            }
            // review text
            .pr-category-snippet__total {
                font-size: 12px;
                line-height: 1em;
                color: @color-gray-darker;
                padding-top: 0;
                padding-bottom: 0;
            }

            .product-pricing {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                align-self: stretch;
            }

            .add-to-cart-button-container {
                display: flex;
                flex-grow: 1;
                justify-content: flex-end;
                flex-direction: column;
                align-self: center;
                width: 80%;
                max-width: 200px;

                .add-to-cart-button {
                    display: flex;
                    height: 32px;
                    padding: 8px 24px;
                    justify-content: center;
                    align-items: center;
                    gap: 8px;
                    align-self: stretch;
                    border-radius: 30px;
                    border: none;
                    background: @color-green-dark;
                    color: @color-white;
                    text-align: center;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 16px;
                }
            }
        }
    }
}

.modal-body {
    .compatible-related-modal-header {
        width: 100%;
        text-align: center;
    }

    .no-related-products-message {
        display: flex;
        justify-content: center;
        font-weight: 800;
        margin-top: 15px;
    }
}

.compatible-related-pdp-button {
    margin-top: 12px;
    margin-bottom: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid @color-green-dark;
    background: @color-white;
    max-width: 100%;
    width: 380px;
    height: 48px;
    padding: 12px;
    gap: 8px;
    border-radius: 10px;
    line-height: 24px;

    span {
        font-weight: 800;
        font-size: 14px;
    }

    svg {
        height: 18px;
        width: 18px;
        color: @color-green-dark;
    }

    svg:last-child {
        justify-self: flex-end;
    }
}